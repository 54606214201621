import * as React from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
// import GSQuantTechLogo from '../../GSQuantTechLogo.jpg';
//import img01transparent from '../../img01transparent.png';
import img01 from '../../img01.png';

export default function Hero() {
  return (
    <Box
      id="hero"
        sx={(theme) => ({
          /*
        width: '100%',
          backgroundImage: `url(${GSQuantTechLogo})`,
            // theme.palette.mode === 'light'
            //    ? 'linear-gradient(180deg, #FDE6DE, #FFFFFD)'
            //    : `linear-gradient(#FFD5D3, ${alpha('#FFFFFD', 0.0)})`,
        backgroundSize: '100% 20%',
          backgroundRepeat: 'no-repeat', */
          mt: { xs: 10, sm: 5 },
          display: 'flex',
          flexDirection: 'column',
          alignSelf: 'center',
          height: { xs: 800, sm: 700 },
          width: '100%',
            backgroundImage: `url(${img01})`, //`url(${GSQuantTechLogo})`,
          backgroundSize: 'cover',
          borderRadius: '10px',
          outline: '1px solid',
          outlineColor:
              theme.palette.mode === 'light'
                  ? alpha('#FAE3DE', 0.5)
                  : alpha('#FCE1DE', 0.1),
          boxShadow:
              theme.palette.mode === 'light'
                  ? `0 0 12px 8px ${alpha('#FCE1DE', 0.2)}`
                  : `0 0 24px 12px ${alpha('#630E03', 0.2)}`,
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 5, sm: 7, md: 10 },
          pb: { xs: 4, sm: 6, md: 10 },
        }}
      >
        <Stack spacing={0} useFlexGap sx={{ width: { xs: '100%', sm: '70%', md: '100%' } }}>
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'column' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(2rem, 7vw, 3rem)',
              bgcolor: (theme) =>
                    theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              color: '#FFFFFF' //(theme) =>
                    //theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
            }}
          >
            &nbsp; Goldman Quant and Technology Consortium &nbsp;
          </Typography>
          {/* }
          <Typography
              // component="span"
              variant="h1"
              sx={{
                fontSize: 'clamp(2rem, 7vw, 3rem)',
                alignSelf: 'center',
                textAlign: 'end',
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              }}
            >
              Consortium
                      </Typography>
          */}
          <Divider sx={{
                      my: 2,
                      opacity: 0.2,
                      //borderColor: 'grey.500',
          }} />
          <Typography
            textAlign="justify"
            //color="text.secondary"
            sx={{
                alignSelf: 'center',
                width: { sm: '100%', md: '80%' },
                pt: { xs: 1, sm: 2, md: 3 },
                px: '2rem',
                py: '2rem',
                bgcolor: '#FFF6F2', // 'primary.light', // '#FFFFFF',
                color: (theme) =>
                    theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
                opacity: 0.8,
                height: { xs: 500, sm: 360 },
                overflow: "hidden",
                overflowY: "scroll",
            }}
          >
            <p>The Goldman Quants and Technology Consortium is a organization of over two hundred former Goldman Sachs quant strategists and technologists.
               Having worked in one of the most elite institutions they also bring deep understanding of the financial products and markets as well as their
               proven ability to use the most sophisticated mathematical models and technologies to deliver elegant and efficient solutions to the most complex problems. </p>
            <p>The purpose of the consortium to leverage the world-class quantitative and technology skills, financial product and market knowledge and experience to create value for each other and clients
               and in the process form various companies which will provide top quality products, services and investment opportunities to our clients.</p>
            <p>This site and the products and services offered on this site are not associated, affiliated, endorsed, or sponsored by The Goldman Sachs Group Inc. Goldman, Sachs & Co or any of their subsidiaries.</p>
          </Typography>
        </Stack>
       {/*
        <Box
          id="image"
          display="flex"
          sx={(theme) => ({
            mt: { xs: 4, sm: 5 },
            alignSelf: 'center',
            height: { xs: 200, sm: 700 },
            width: '100%',
            backgroundImage: `url(${GSQuantTechLogo})`,
            backgroundSize: 'cover',
            borderRadius: '10px',
            outline: '1px solid',
            outlineColor:
              theme.palette.mode === 'light'
                ? alpha('#FAE3DE', 0.5)
                : alpha('#FCE1DE', 0.1),
            boxShadow:
              theme.palette.mode === 'light'
                ? `0 0 12px 8px ${alpha('#FCE1DE', 0.2)}`
                : `0 0 24px 12px ${alpha('#630E03', 0.2)}`,
          })}
        /> */}
      </Container>
    </Box>
  );
}
