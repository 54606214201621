import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';
//import Ron from 'RonChoi.jpg'

const userTestimonials = [
  /*{
    avatar: <Avatar alt="Javed Golapati" src="JavedGolapati.png" />,
    name: 'Javed Golapati',
    occupation: 'Global Head of FX',
    testimonial:
      "This was a fantastic idea to connect and leverage the mass pool of proven talents that are out there to build amazing top-class solutions to large scale, complex problems.",
  },*/
  {
    avatar: <Avatar alt="Ron Choi" src="RonChoi.jpg" />,
    name: 'Ron Choi',
    occupation: 'Tech Manager',
    testimonial:
      "One of the great thing about this consortium is the people behind it. In my experience, the team behind this consortium has been quick to respond and incredibly helpful. It's reassuring to know that they stand firmly behind their service.",
  },
  {
    avatar: <Avatar alt="Cindy Oh" src="YSOh.jpg" />,
    name: 'Cindy Oh',
    occupation: 'Software Developer',
    testimonial:
      'I absolutely love connecting with my old colleagues! This consortium allowed me to reconnect with my talented ex-colleagues and helped me keep up-to-date on the latest in the industry.',
  },
  /*{
      avatar: <Avatar alt="Sergey Romanov" src="SergeyRomanov.png" />,
      name: 'Sergey Romanov',
    occupation: 'Hedge Fund Partner',
    testimonial:
      "This consortium allowed me to leverage the world class talents for new ventures.",
  },*/
  {
      avatar: <Avatar alt="Shailen Bellare" src="ShailenBellare.jpg" />,
      name: 'Shailen Bellare',
    occupation: 'Senior Quant Developer',
    testimonial:
      "Absolutely love getting involved and hearing great and interesting ideas.",
  },
  /*{
      avatar: <Avatar alt="Adnan Khan" src="AdnanKhan.png" />,
      name: 'Adnan Khan',
    occupation: 'Hedge Fund Manager',
    testimonial:
      "Should have done this a long time ago.  What a great venture and great people!",
  },*/
];

const whiteLogos = [
    //'https://upload.wikimedia.org/wikipedia/commons/2/20/Bank_of_America_logo.svg',
    'https://upload.wikimedia.org/wikipedia/commons/2/20/JPMorgan_logo.svg',
    'https://upload.wikimedia.org/wikipedia/commons/6/61/Goldman_Sachs.svg',
    //'GSACapital.svg',
    'Barclays-Logo.wine.svg',
    //'ExodusPointCapitalManagement.svg',
  //'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628e8573c43893fe0ace_Sydney-white.svg',
  //'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d520d0517ae8e8ddf13_Bern-white.svg',
  //'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f46794c159024c1af6d44_Montreal-white.svg',
  //'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e891fa22f89efd7477a_TerraLight.svg',
  //'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a09d1f6337b1dfed14ab_colorado-white.svg',
  //'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5caa77bf7d69fb78792e_Ankara-white.svg',
];

const darkLogos = [
  //'https://upload.wikimedia.org/wikipedia/commons/2/20/Bank_of_America_logo.svg',
  'https://upload.wikimedia.org/wikipedia/commons/2/20/JPMorgan_logo.svg',
  'https://upload.wikimedia.org/wikipedia/commons/6/61/Goldman_Sachs.svg',
  //'GSACapital.svg',
  'Barclays-Logo.wine.svg',
  //'ExodusPointCapitalManagement.svg',
  //'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628889c3bdf1129952dc_Sydney-black.svg',
  //'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d4d8b829a89976a419c_Bern-black.svg',
  //'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f467502f091ccb929529d_Montreal-black.svg',
  //'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e911fa22f2203d7514c_TerraDark.svg',
  //'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a0990f3717787fd49245_colorado-black.svg',
  //'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5ca4e548b0deb1041c33_Ankara-black.svg',
];

const logoStyle = {
  width: '64px',
  opacity: 1,
};

export default function Testimonials() {
  const theme = useTheme();
  const logos = theme.palette.mode === 'light' ? darkLogos : whiteLogos;

  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h4" color="primary.dark">
          Testimonials
        </Typography>
        <Typography variant="body1" color="primary.main">
          See what our members love about our services. Join us for the quality of our community and the immense opportunies that the consortium provides.
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {userTestimonials.map((testimonial, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1,
                p: 1,
              }}
            >
              <CardContent>
                <Typography variant="body2" color="primary.main">
                  {testimonial.testimonial}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  pr: 2,
                  //color: 'primary.main'
                }}
              >
              <CardHeader
                  sx={{color: 'primary.dark'}}
                  avatar={testimonial.avatar}
                  title={testimonial.name}
                  subheader={testimonial.occupation}
                />
                <img
                  src={logos[index]}
                  alt={`Logo ${index + 1}`}
                  style={logoStyle}
                />
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
