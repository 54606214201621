import { Component } from 'react';
import LandingPage from './landing-page/LandingPage';

class App extends Component {
    render() {
        return (
            <div className="App">
                <div className="App-intro">
                    <LandingPage />
                </div>
            </div>
        );
    }
}

export default App;
